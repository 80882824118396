import { Navigate } from "react-router-dom";
const Protected = ({ children }) => {
  const isLoggedIn = localStorage.getItem("login");
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  } else {
    // return <Navigate to="/home" replace />;
  }
  return children;
};
export default Protected;
