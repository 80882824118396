import React, { useEffect, useState } from "react";
import Icon from "../components/icon";
import Img3 from "../assets/images/img3.svg";
import TableRow from "../components/tableRow";
import Papa from "papaparse";
import rental from "../assets/csv/leasing-&-rental.csv";
import { useParams } from "react-router-dom";
import detail from "../assets/csv/detail.csv";
import materials from "../assets/csv/materials_in_chemicals-materials-&-Nutrition.csv";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

function Listing() {
  const { name, cateogry } = useParams();
  const [data, setData] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationData, setPaginationData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [pagePerItem, setPagePerItem] = useState(30);
  const showFullItem = (e) => {
    setPaginationData(data);
    e.target.remove();
    setTotalPage(1);
    console.log(data);
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        name === "leasing-&-rental" ? rental : materials
      );
      const reader = response.body.getReader();
      const result = await reader.read();
      const decoder = new TextDecoder("utf-8");
      const csvData = decoder.decode(result.value);
      const parsedData = Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
      }).data;
      setData(parsedData);
      setTotalPage(Math.ceil(parsedData.length / pagePerItem));
      const currentData = parsedData;
      setPaginationData(
        currentData.slice(
          currentPage * pagePerItem,
          (currentPage + 1) * pagePerItem
        )
      );
    };
    fetchData();
    const fetchDetail = async () => {
      const response = await fetch(detail);
      const reader = response.body.getReader();
      const result = await reader.read();
      const decoder = new TextDecoder("utf-8");
      const csvData = decoder.decode(result.value);
      const parsedData = Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
      }).data;
      setDetailData(parsedData);
    };
    fetchDetail();
  }, []);
  useEffect(() => {
    const currentData = data;
    setPaginationData(
      currentData.slice(
        currentPage * pagePerItem,
        (currentPage + 1) * pagePerItem
      )
    );
  }, [currentPage]);
  return (
    <section className="journey">
      <div className="container">
        <div className="breadcrumbs-wrap">
          <ul className="breadcrumbs">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="capitalize">
              {cateogry} --> {name?.trim().replace(/-/g, " ")}
            </li>
          </ul>
          <div className="btn-back">
            <Link to="/">
              <Icon icon="home" />
              Back to Home
            </Link>
          </div>
        </div>
        <div className="box">
          <div className="heading">
            <h1>{name.trim().replace(/-/g, " ")}</h1>
            <div className="header-box">
              <form className="search-holder">
                <input type="search" placeholder="Search" />
              </form>
              <div className="cvs-wrap">
                <CSVLink className="btn btn-blue" data={data}>
                  Export CSV <Icon size="20" icon="download" />
                </CSVLink>
              </div>
            </div>
          </div>
          <div className="table-holder">
            <table>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Company URL</th>
                  <th>Region</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {paginationData.length
                  ? paginationData.map((row, index) => (
                      <TableRow
                        index={index}
                        key={index}
                        img={Img3}
                        title={row.Name}
                        url={row.URL}
                        region={row.Region}
                        length={
                          detailData.filter(
                            (item) =>
                              item["Company Name"]?.trim() == row.Name?.trim()
                          ).length
                        }
                      ></TableRow>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          <button className="btn-view-all" onClick={showFullItem}>
            View All List
          </button>
        </div>
        <div className="pagination-btns">
          {currentPage !== 0 && (
            <button
              className="btn btn-prev"
              onClick={(e) => setCurrentPage(currentPage - 1)}
            >
              Prev
            </button>
          )}
          {totalPage - 1 !== currentPage && (
            <button
              className="btn btn-next"
              onClick={(e) => setCurrentPage(currentPage + 1)}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </section>
  );
}

export default Listing;
