function TableRowDetail(props) {
  return (
    <tr key={props.index}>
      <td className="question">
        <div>
          <span className="item_num">
            {parseInt(props.page) * 30 + parseInt(props.index) + 1}.
        </span>
          {props.question}
        </div>
      </td>
      <td>{props.answer}</td>
      <td>&nbsp;</td>
    </tr>
  );
}

export default TableRowDetail;
