import { Link } from "react-router-dom";

function TableRow(props) {
  return (
    <tr>
      <td>
        <div className="company">
          <div className="company-img">
            <img src={props.img} alt="Image Description" />
          </div>
          <span className="company-title">{props.title}</span>
        </div>
      </td>
      <td>
        <a href={props.url} target="_blank">
          {props.url}
        </a>
      </td>
      <td>{props.region}</td>
      <td>
        {props.length > 0 ? (
          <Link
            to={`/company/${props.title
              .toLowerCase()
              ?.trim()
              .replace(/\s/g, "-")}`}
            className="btn"
          >
            Details
          </Link>
        ) : null}
      </td>
    </tr>
  );
}

export default TableRow;
