import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.png";
import Input from "../components/input";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  let navigate = useNavigate();
  const checkLogin = (event) => {
    event.preventDefault();
    if (userName === "frost" && password === "frost") {
      localStorage.setItem("login", "logedin");
      navigate("/");
      const notify = () => toast("Welcome!!!");
      notify();
    } else {
      const notify = () =>
        toast.error("Username or Password incorrect", {
          theme: "light",
          position: "bottom-right",
        });
      notify();
    }
  };
  useEffect(() => {}, [userName, password]);
  const auth = localStorage.getItem("login");
  if (auth) {
    return <Navigate to="/" replace />;
  } else {
    return (
      <div className="login">
        <div className="login-holder">
          <div className="logo">
            <Link to="/">
              <img src={logo} className="App-logo" alt="logo" />
            </Link>
          </div>
          <form action="#" className="login-form">
            <h1>Login</h1>
            <Input
              type="text"
              label="Username"
              placeholder="Enter your username"
              id="username"
              name="uname"
              onKeyUp={(e) => setUserName(e.target.value)}
            ></Input>
            <Input
              type="password"
              label="Password"
              placeholder="Enter your password"
              id="password"
              name="password"
              onKeyUp={(e) => setPassword(e.target.value)}
            ></Input>
            <div className="login-info">
              <div className="login-checkbox">
                <label>
                  <input type="checkbox" />
                  <div className="checkbox-slide"></div>
                  Remember me
                </label>
              </div>
              <div className="login-forget">
                <a href="#">Forgot password?</a>
              </div>
            </div>
            <input
              type="submit"
              value="Login"
              className="btn"
              onClick={checkLogin}
            />
          </form>
        </div>
        <ToastContainer></ToastContainer>
      </div>
    );
  }
}

export default Login;
