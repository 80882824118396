import { Outlet } from "react-router-dom";
import Header from "./partials/header";
import Protected from "../components/Protected";
function Layout() {
  return (
    <Protected>
      <div className="wrapper">
        <Header></Header>
        <main className="main">
          <Outlet />
        </main>
      </div>
    </Protected>
  );
}

export default Layout;
