import React, { useState } from "react";
import Icon from "./icon";

function Input(props) {
  const [show, setShow] = useState(false);
  const [type, setType] = useState("password");

  const showPassword = (e) => {
    e.preventDefault();
    setShow(!show);
    setType(type === "password" ? "text" : "password");
  };

  return (
    <div className="form-group">
      <label for={props.id}>{props.label}</label>
      <div className="input-holder">
        <input
          type={props.type === "password" ? type : props.type}
          name={props.name}
          placeholder={props.placeholder}
          id={props.id}
          onKeyUp={props.onKeyUp}
        />
        {props.type === "password" && (
          <button
            onClick={showPassword}
            className={show ? "show btn-icon" : "hide btn-icon"}
          >
            <Icon icon={show ? "eye" : "eye-hide"} />
          </button>
        )}
      </div>
    </div>
  );
}

export default Input;
