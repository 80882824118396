import React, { useState } from "react";
import Icon from "../components/icon";
import { Link, json } from "react-router-dom";
import {
  ControlledAccordion,
  AccordionItem,
  useAccordionProvider,
} from "@szhsin/react-accordion";
import Item from "../components/item";
import { CSVLink } from "react-csv";
import { ToastContainer, toast } from "react-toastify";

function Home() {
  const providerValue = useAccordionProvider({
    allowMultiple: true,
    transition: true,
    transitionTimeout: 250,
  });
  const [csvName, setCsvName] = useState(
    localStorage.getItem("filename") ? localStorage.getItem("filename") : ""
  );
  const [csvData, setCsvData] = useState(
    localStorage.getItem("file") ? localStorage.getItem("file") : []
  );

  const [show, setShow] = useState(false);
  const { toggle, toggleAll } = providerValue;
  const accToggle = () => {
    setShow(!show);
    show ? toggleAll(false) : toggleAll(true);
  };
  const fileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      const csvContent = reader.result;
      localStorage.setItem("filename", e.target.files[0].name);
      localStorage.setItem("file", csvContent);
      setCsvData(csvContent);
      setCsvName(e.target.files[0].name);
      toast.success("File has been uploaded!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });

    if (file) {
      reader.readAsText(file);
    }
  };
  return (
    <section className="journey">
      <div className="container">
        <div className="breadcrumbs-wrap">
          <ul className="breadcrumbs">
            <li>Home</li>
          </ul>
          <div className="btn-back">
            <Link to="/">
              <Icon icon="home" />
              Back to Home
            </Link>
          </div>
        </div>
        <div className="box">
          <div className="heading">
            <h1>Select Transformational Journey</h1>
            <div className="header-box header-box--big">
              <form className="search-holder">
                <input type="search" placeholder="Search" />
              </form>
              <div className="cvs-wrap">
                {csvData.length === 0 ? (
                  <form className="form-file">
                    <input type="file" onChange={fileUpload} accept=".csv" />
                    <button className="btn btn-blue">Upload a File</button>
                  </form>
                ) : (
                  <div className="btns-list">
                    <form className="form-file">
                      <input type="file" onChange={fileUpload} accept=".csv" />
                      <button className="btn btn-blue">
                        Upload a New File
                      </button>
                    </form>
                    <CSVLink
                      download={csvName}
                      className="btn btn-blue"
                      data={csvData}
                    >
                      Download <Icon size="20" icon="download" />
                    </CSVLink>
                  </div>
                )}
              </div>
            </div>
          </div>
          <span className="list-title">List</span>
          <ControlledAccordion className="acc" providerValue={providerValue}>
            <AccordionItem header="Mobility">
              <ul className="acc-list">
                <Item title="BIW" />
                <Item title="Connectivity" />
                <Item title="Human Machine Interface" />
                <Item
                  title="Leasing & Rental"
                  link="/mobility/leasing-&-rental"
                />
              </ul>
            </AccordionItem>
            <AccordionItem header="Chemicals, Materials & Nutrition">
              <ul className="acc-list">
                <Item
                  title="Materials"
                  link="chemicals-materials-&-nutrition/materials"
                />
              </ul>
            </AccordionItem>

            <AccordionItem header="Healthcare & Lifesciences">
              <ul className="acc-list">
                <Item title="Chronic Care" />
              </ul>
            </AccordionItem>
            <AccordionItem header="Aerospace & Defense">
              <ul className="acc-list">
                <Item title="Chronic Care" />
              </ul>
            </AccordionItem>
            <AccordionItem header="Industrial">
              <ul className="acc-list">
                <Item title="Chronic Care" />
              </ul>
            </AccordionItem>
            <AccordionItem header="Energy">
              <ul className="acc-list">
                <Item title="Chronic Care" />
              </ul>
            </AccordionItem>
            <AccordionItem header="Environment">
              <ul className="acc-list">
                <Item title="Chronic Care" />
              </ul>
            </AccordionItem>
          </ControlledAccordion>
          <button className="btn-view-all" onClick={() => accToggle()}>
            {show === true ? "Hide" : "View"} All List
          </button>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </section>
  );
}

export default Home;
