import { Link } from "react-router-dom";
function Item(props) {
  return (
    <li>
      <span className="acc-title">{props.title}</span>
      <div className="acc-button">
        {props.link && (
          <Link className="btn" to={props.link}>
            {props.linkText ? props.linkText : "Generate"}
          </Link>
        )}
      </div>
    </li>
  );
}

export default Item;
