import Icon from "../../components/icon";
import user from "../../assets/images/user.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
function Header() {
  const [dropdown, setDropdown] = useState(false);
  const navigate = useNavigate();
  const logOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("login");
    navigate("/login");
  };
  return (
    <header className="header">
      <div className="container">
        <button className="nav-opener">
          <span></span>
        </button>
        <form className="search-form">
          <div className="search-wrap">
            <input type="search" placeholder="Search" />
          </div>
        </form>
        <div className="header-area">
          <div className="notification">
            <button className="notification__btn">
              <Icon icon="bell" />
              <span className="notification__num">4</span>
            </button>
          </div>
          <div className={dropdown ? "active user-block" : "user-block"}>
            <button
              className="user-opener"
              onClick={(e) => setDropdown(!dropdown)}
            >
              <img src={user} alt="Image Description" />
            </button>
            <ul className="dropdown">
              <li>
                <button onClick={logOut}>Logout</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
