import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./layout/login";
import Home from "./layout/home";
import Listing from "./layout/listing";
import Detail from "./layout/detail";
import Layout from "./layout/layout";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<Login />}></Route>
          <Route exact path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path=":cateogry/:name" element={<Listing />} />
            <Route path="company/:name" element={<Detail />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
